<template>
    <div id="messages-container" class="messages-container flex flex-col">
        <div v-for="(day, value) in messages" :key="value">
            <div class="flex flex-col items-center my-6">
                <span class="flex w-full border-b" />
                <p class="px-2 py-1 rounded-full border-white border-4 text-xs bg-gray-200 text-gray-500" style="margin-top: -15px">
                    {{ value }}
                </p>
            </div>
            <Bubble v-for="message in day" :key="message.id" :position="message.sender_role === 'employee' ? 'left' : 'right'">
                <template #message>
                    {{ message.message }}
                </template>
                <template #time>
                    {{ message.created_at }}
                </template>
            </Bubble>
        </div>

        <InitialLoader v-if="$wait.is('loading.initial_*')" />
    </div>
</template>
<script>
export default {
    components: {
        Bubble: () => import(/* webpackChunkName: "MessagesList/Bubble" */ './Bubble'),
    },

    props: {
        messages: {
            type:    Object,
            default: () => {},
        },
    },

    watch: {
        messages() {
            // await this.$nextTick();

            this.$nextTick()
                .then(() => {
                    const objDiv = document.getElementById('messages-container');
                    objDiv.scrollTop = objDiv.scrollHeight;
                    console.log('🚀 ~ file: MessagesList.vue ~ line 31 ~ .then ~ objDiv.scrollHeight', objDiv.scrollHeight);
                });
            // const objDiv = document.getElementById('messages-container');
            // objDiv.scrollTop = objDiv.scrollHeight;
        },
    },

    methods: {
        // getPosition(sender) {
        //     if(sender ===)
        // }
    },
};
</script>
<style scoped>
    .messages-container {
        height: calc(100vh - 201px);
        padding: 0 10px;
        margin-bottom: 50px;
        overflow-y: scroll;
    }
</style>
